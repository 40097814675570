import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LibraryCase } from './case';
import { LibraryHome } from './home';
import { LibraryCategory } from './category';

// we want to import a list of folders to get the categories

export const Library: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<LibraryHome />} />
      <Route path='/:category' element={<LibraryCategory />} />
      <Route path='/:category/:caseName' element={<LibraryCase />} />

      {/* redirect to library */}
      <Route path='*' element={<LibraryHome />} />
    </Routes>
  );
};
