/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import criminalImage from '../images/criminal.png';
import Icon from '../../../../assets/icons/home.svg?react';
import bubbleImage from '../images/category-icon-image.png';

const TITLE = 'Real estate';

const realEstate: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Real estate law in Australia',
  image: criminalImage,
  previewImage: criminalImage,
  shortDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc',
  cases: cases.filter((c) => c.category === TITLE),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Property Law Act',
      body: 'This legislation governs property ownership, transfer of property rights, and the legal processes involved in buying, selling, or leasing real estate.'
    },
    {
      header: 'Land Titles and Ownership',
      body: 'Real estate law establishes the framework for land titles, defining property ownership rights, including freehold, leasehold, and joint ownership structures.'
    },
    {
      header: 'Contracts for Sale and Purchase',
      body: 'The law mandates formal agreements for real estate transactions, detailing obligations, disclosures, and rights of both buyers and sellers to ensure fair dealing.'
    },
    {
      header: 'Leasing and Tenancy Laws',
      body: 'These laws set out the rights and responsibilities of landlords and tenants, including rules around rent, maintenance, and termination of leases.'
    },
    {
      header: 'Zoning and Land Use Regulations',
      body: 'Local zoning laws and land use regulations control property development, specifying permitted uses for different areas, building standards, and environmental protections.'
    }
  ],
};

export default realEstate;
