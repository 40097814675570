import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { libraryAssets } from '../assets';
import { convertToKebabCase } from '../../../utils/case-conversion';
import { Header } from '../../../components/header';
import { LibraryHero } from '../hero';
import { NeedHelpCard } from '../need-help';
import { LibraryCarousel } from '../carousel';
import { Footer } from '../../../components/footer';
import style from './style.module.less';
import { CategoryCard } from '../category-card';

export const LibraryCategory: FC = () => {
  const { category } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const libraryCategory = libraryAssets.find(
    (c) => convertToKebabCase(c?.name) === category
  );
  const extraCategories = libraryAssets.filter(
    (c) => convertToKebabCase(c?.name) !== category
  );
  useEffect(() => {
    // if no category or case found, redirect to library
    if (!libraryCategory) {
      // navigate('/library');
      console.log('No category found');
    }
  }, [libraryCategory, navigate]);

  if (!libraryCategory) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        title={libraryCategory.name}
        description={libraryCategory.title}
        image={libraryCategory.image}
      />

      <div className={style.description}>{libraryCategory.description}</div>

      <div className={style.bodyContainer}>
        <div className={style.bodyImageContainer}>
          <img
            src={libraryCategory.bubbleImage}
            alt={libraryCategory.name}
            className={style.bodyImage}
          />
        </div>
        <div className={style.bodyTextContainer}>
          {libraryCategory.bodyText.map((text, index) => (
            <div key={index} className={style.bodyText}>
              <div className={style.bodyTextTitle}>{text.header}</div>
              <div className={style.bodyTextDescription}>{text.body}</div>
            </div>
          ))}
        </div>
      </div>

      <LibraryCarousel
        title={'Explore some common cases created on LawConnect'}
        cases={libraryCategory.cases}
        titleClassName={style.carouselTitle}
        
      />
      <NeedHelpCard />

      <div className={style.moreCategories}>
        <div className={style.moreCategoriesTitle}>
          Explore more areas of law
        </div>
        <div className={style.categoryCardsContainer}>
          {extraCategories.map((category, index) => (
            <CategoryCard 
              key={index} 
              category={category} 
              titleClassName={style.categoryCardTitle}
              iconContainerClassName={style.categoryCardIconContainer}
              iconClassName={style.categoryCardIcon}
            />
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};
