import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { Footer } from '../../../components/footer';
import { LibraryHero } from '../hero';
import { libraryAssets } from '../assets';
import style from './style.module.less';
import homeHeroImage from '../assets/images/criminal.png';
import { CategoryCard } from '../category-card';

export const LibraryHome = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pages.library')}</title>
      </Helmet>
      <Header />
      <div className={style.menuDivider} />
      <LibraryHero
        title={'Library'}
        description={'Published example reference cases'}
        image={homeHeroImage}
      />
      <div className={style.container}>
        <div className={style.descriptionContainer}>
          We have curated some common example legal cases created by our users
          from common areas of law. Browse the categories for your area of
          interest.
        </div>
        <div className={style.categoriesContainer}>
          {libraryAssets.map((category) => (
            <CategoryCard key={category.name} category={category} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
