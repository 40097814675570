/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import criminalImage from '../images/criminal.png';
import Icon from '../../../../assets/icons/user.svg?react';
import bubbleImage from '../images/category-icon-image.png';

const TITLE = 'Family Law';

const familyLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Family law in Australia',
  image: criminalImage,
  previewImage: criminalImage,
  shortDescription:
    'Navigating family law in Australia can be complex and challenging.',
  description:
    'Family law is the area of law that deals with family matters and domestic relations, including marriage, civil unions, and domestic partnership. ',
  cases: cases.filter((c) => c.category === TITLE),
  Icon,
  bubbleImage,
  bodyText: [
    {
      header: 'Family Law Act 1975',
      body: 'The foundational legislation governing family law in Australia, covering marriage, divorce, property settlements, and arrangements for children post-separation.'
    },
    {
      header: 'Divorce and Separation',
      body: 'Family law outlines the processes for legally ending a marriage or partnership, including the requirements for divorce and provisions for property division.'
    },
    {
      header: 'Child Custody and Parenting Arrangements',
      body: 'In cases of separation, the law prioritizes the best interests of the child, establishing guidelines for parenting arrangements, custody, and visitation rights.'
    },
    {
      header: 'Property and Financial Settlements',
      body: 'Family law includes principles for dividing assets and liabilities fairly between partners, considering factors like financial contributions and future needs.'
    },
    {
      header: 'Family Violence and Protection Orders',
      body: 'Family law protects individuals from domestic violence through intervention orders, which place restrictions on behavior to ensure safety for victims and children.'
    }
  ],
};

export default familyLaw;
