import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { LegalCaseState } from '@law-connect/types';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions } from '../redux/slices';
import selectors from '../redux/selectors';
import { Account } from './account';
import { About } from './about';
import { Terms } from './terms';
import { Privacy } from './privacy';
import { HowItWorks } from './how-it-works';
import { PrematterRouter } from './prematter';
import { Home } from './home';
import { AnonymousChat } from './anonymous-chat';
import { ScrollToTop } from '../components/scroll-to-top';
import {
  CookieConsent,
  CookieConsentResult,
} from '../components/cookie-consent';
import { security } from '../redux/api/security';
import { EditAccount } from './edit-account';
import { NotFoundComponent } from './not-found';
import { MaintenanceComponent } from './maintenance';
import ReactGA from 'react-ga4';
import env from '../constants/env';
import { clarity } from 'react-microsoft-clarity';
import { ToastWrapper } from '../components/toast';
import { Collector } from '../utils/collector';
import { LawyerConnection } from './lawyer-connection';
import { AUTH_MODAL_PORTAL_ID } from '../constants';
import bodyStyles from '../styles/theme.module.less';
import { 
  LawyerConnectionClientIntakeForm
} from './lawyer-connection/client-intake-form';
import { MaxwellChat } from './maxwell';
import { Library } from './library';

// Start seeing data on the Clarity dashboard with your id
clarity.init(env.CLARITY_PROJECT_ID);

interface PrivateRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
}

function PrivateRoute(args: PrivateRouteProps) {
  const { component } = args;
  const { isAuthenticated, isLoading, login } = useKindeAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const RenderComponent = component;
  const user = useAppSelector(selectors.user.getUser());
  const location = useLocation();

  const prematters = useAppSelector(selectors.prematter.getAll());
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actions.user.fetch());
    } else if (!isLoading && !isAuthenticated) {
      // Send user to login page
      login({
        // eslint-disable-next-line camelcase
        app_state: {
          redirectTo:
            location.state?.from?.pathname ||
            window.location.pathname ||
            '/account',
        },
      });
    }
  }, [
    dispatch,
    isAuthenticated,
    isLoading,
    location.state?.from?.pathname,
    login,
    navigate,
  ]);

  useEffect(() => {
    if (isAuthenticated && prematters && prematters.length) {
      // if any of the prematters are in progress, refresh the list every 5 seconds
      const preparingReport = prematters.some(
        (prematter) => prematter?.state === LegalCaseState.PreparingReport
      );
      const processingQuestions = prematters.some(
        (prematter) =>
          prematter?.context?.questions?.filter((q) => q.processing).length > 0
      );
      if (preparingReport || processingQuestions) {
        const interval = setInterval(() => {
          // if any of the prematters are in progress, refresh the list every 5 seconds
          const preparingReport = prematters.some(
            (prematter) => prematter.state === LegalCaseState.PreparingReport
          );
          const processingQuestions = prematters.some(
            (prematter) =>
              prematter.context?.questions?.filter((q) => q.processing).length >
              0
          );
          if (!preparingReport && !processingQuestions) {
            clearInterval(interval);
            return;
          }
          dispatch(actions.prematter.fetch());
        }, 5000);
        return () => clearInterval(interval);
      }
    }
  }, [dispatch, isAuthenticated, prematters]);

  if (isLoading || !user) {
    return null;
  } else if (!isAuthenticated) {
    navigate('/');
    return <div>Redirecting...</div>;
  }
  return <RenderComponent />;
}

const LogoutComponent: React.FC = () => {
  const { logout } = useKindeAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const logTheUserOut = async () => {
      await logout();
      // TOOD: Call dispatch to reset the redux store
      navigate('/login');
    };
    logTheUserOut();
  }, []);

  return null;
};

const AuthCallbackComponent: React.FC = () => {
  const { isLoading, error, isAuthenticated } = useKindeAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && error) {
      console.error('Error logging in', error);
      navigate('/');
    } else if (!isLoading && !isAuthenticated) {
      console.error('User is not authenticated - but why?');
      navigate('/');
    } else if (!isLoading && isAuthenticated) {
      navigate('/account');
    }
  }, [isLoading, error, navigate, isAuthenticated]);

  return null;
};

const AppWrapper = () => {
  const { isLoading, getToken, isAuthenticated, user } = useKindeAuth();

  // I really don't like this here as it's run on each render.
  // However, it's low cost and it's the only way to set the isAuthenticated state
  // before the first render, keeping it in sync with the auth state.
  security.setIsAuthenticated(isAuthenticated);

  useLayoutEffect(() => {
    const path = window.location.pathname;
    if (/^\/(au|uk|us|ca|nz)\b/.test(path)) {
      // Redirect to lawtap.com keeping path
      window.location.href = `https://lawtap.com${path}`;
    }
  }, []);

  useEffect(() => {
    security.setGetAccessToken(getToken);
  }, [getToken]);

  useEffect(() => {
    if (isAuthenticated && user) {
      // Set clarity custom tag for kinde user id
      clarity.setTag('Kinde User Id', user?.id);
    }
  }, [isAuthenticated, user]);

  const dispatch = useAppDispatch();
  const isFetchPending = useAppSelector(
    selectors.session.isFetchSessionPending()
  );
  const sessionId = useAppSelector(selectors.session.getSessionId());
  const isDeletingSession = useAppSelector(
    selectors.session.isDeleteSessionPending()
  );
  const fetchSessionError = useAppSelector(selectors.session.fetchError());

  const userDetail = useAppSelector(selectors.user.getUser());

  const handleCookeResult = (result: CookieConsentResult) => {
    if (result === CookieConsentResult.ACCEPT) {
      // Cookie consent
      ReactGA.initialize(
        env.GA_MEASUREMENT_ID
        // {
        //   testMode:
        //     env.PUBLIC_URL.includes('localhost'),
        // }
      );
      clarity.consent();
    }
  };

  useEffect(() => {
    if (
      !isLoading &&
      !isFetchPending &&
      !sessionId &&
      !fetchSessionError &&
      !isDeletingSession
    ) {
      dispatch(actions.session.fetch());
    }
  }, [
    dispatch,
    isFetchPending,
    sessionId,
    fetchSessionError,
    isLoading,
    isDeletingSession,
  ]);

  useEffect(() => {
    if (fetchSessionError) {
      // On error, retry fetch, and set interval to retry every 10 seconds
      // retry fetching the session in 10 seconds
      const interval = setInterval(() => {
        dispatch(actions.session.fetch());
      }, 10000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSessionError]);

  useEffect(() => {
    if (isAuthenticated && !userDetail) {
      dispatch(actions.user.fetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userDetail]);

  useEffect(() => {
    document.body.classList.add(bodyStyles.body);
  }, []);

  // if (fetchSessionError) {
  //   // We could not get a session, that is a problem, but we cannot continue without it.
  //   return (
  //     <Router>
  //       <MaintenanceComponent />
  //     </Router>
  //   );
  // }

  if (isLoading) {
    // This is to prevent kinde auth from redirecting to the homepage
    // so if it is loading, return null
    return null;
  }
  return (
    <Router>
      <Collector userId={userDetail?.id} />
      <Helmet>
        <title>LawConnect</title>
      </Helmet>
      <ScrollToTop />
      <div id={AUTH_MODAL_PORTAL_ID} />
      <Routes>
        {fetchSessionError && !isLoading ? (
          <Route path='*' element={<MaintenanceComponent />} />
        ) : (
          <>
            <Route path='/' element={<Home />} />
            <Route path='/auth/*' element={<AuthCallbackComponent />} />

            {/* <Route path='/maxwell' element={<MaxwellChat />} /> */}

            <Route path='/chat/*' element={<MaxwellChat />} />
            <Route path='/about' element={<About />} />
            <Route path='/how-it-works' element={<HowItWorks />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/library/*' element={<Library />} />

            <Route path='/account-force' element={<Account />} />
            <Route path='/logout' element={<LogoutComponent />} />
            {/* Authenticated routes */}
            <Route
              path='/account'
              element={<PrivateRoute component={Account} />}
            />
            <Route
              path='/account/edit'
              element={<PrivateRoute component={EditAccount} />}
            />
            <Route
              path='/lawyer-connection/:id'
              element={<PrivateRoute component={LawyerConnection} />}
            />

            <Route
              path='/lawyer-connection/:id/client-intake-form'
              element={
                <PrivateRoute component={LawyerConnectionClientIntakeForm} />
              }
            />
            <Route
              path='/case/:id/*'
              element={<PrivateRoute component={PrematterRouter} />}
            />
            <Route path='/maintenance' element={<MaintenanceComponent />} />

            {/* This is a legacy/old lawconnect page */}
            <Route path='/au' element={<Home />} />

            <Route path='*' element={<NotFoundComponent />} />
          </>
        )}
      </Routes>
      <CookieConsent onResult={handleCookeResult} />
      <ToastWrapper />
    </Router>
  );
};

export default AppWrapper;
