/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import criminalImage from '../images/criminal.png';
import ShieldPlusIcon from '../../../../assets/icons/shield-plus.svg?react';
import bubbleImage from '../images/category-icon-image.png';

const TITLE = 'Criminal Law';

const criminalLaw: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Criminal law in Australia',
  image: criminalImage,
  previewImage: criminalImage,
  shortDescription:
    'Maintain order, protect individuals and property, and uphold justice.',
  description:
    'Criminal law is the body of law that relates to crime. It regulates social conduct and proscribes threatening, harming, or otherwise endangering the health, safety, and moral welfare of people. It includes the punishment of people who violate these laws. Criminal law varies according to jurisdiction, and differs from civil law, where emphasis is more on dispute resolution and victim compensation than on punishment.',
  cases: cases.filter((c) => c.category === TITLE),
  Icon: ShieldPlusIcon,
  bubbleImage,
  bodyText: [
    {
      header: 'Criminal Code Act 1995',
      body: 'The primary legislation governing criminal offenses in Australia, detailing crimes and outlining penalties, procedures, and jurisdictional authority for federal offenses.'
    },
    {
      header: 'Types of Criminal Offenses',
      body: 'Criminal law divides offenses into categories, including summary (less severe) and indictable offenses (more severe), covering areas like theft, assault, drug trafficking, and fraud.'
    },
    {
      header: 'The Right to a Fair Trial',
      body: 'Individuals accused of a crime are entitled to a fair and public hearing by an impartial tribunal, with legal representation and protections against self-incrimination.'
    },
    {
      header: 'Sentencing and Penalties',
      body: 'Criminal law establishes sentencing guidelines, which consider factors like the severity of the offense, prior criminal history, and potential for rehabilitation to determine appropriate penalties.'
    },
    {
      header: 'Victims’ Rights and Support',
      body: 'Victims of crime have rights to information, protection, and support services, including assistance with court proceedings and access to compensation and counseling services.'
    }
  ],
};

export default criminalLaw;
