import React, { FC } from 'react';
import style from './style.module.less';

interface Props {
  title: string;
  description: string;
  image: string;
}

export const LibraryHero: FC<Props> = ({ title, description, image }) => {
  return (
    <div className={style.heroCard}>
      <div className={style.background} />
      <div className={style.backgroundFooter}>
        <div className={style.backgroundFooterImage} />
        <div className={style.backgroundFooterImage} />
        <div className={style.backgroundFooterImage} />
        <div className={style.backgroundFooterImage} />
      </div>
      <div className={style.titleWrapper}>
        <div className={style.title}>{title}</div>
        <div className={style.descriptionCard}>{description}</div>
      </div>
      <div className={style.heroImageWrapper}>
        <img src={image} alt='scale' className={style.heroImage} />
      </div>
    </div>
  );
};
