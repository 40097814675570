import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import style from './style.module.less';
import { LibraryCategory } from '../../../types/library';
import { Link } from 'react-router-dom';
import { convertToKebabCase } from '../../../utils/case-conversion';

interface Props {
  category: LibraryCategory;
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  imageClassName?: string;
  iconClassName?: string;
  iconContainerClassName?: string;
}

export const CategoryCard: FC<Props> = ({
  category,
  className,
  titleClassName,
  descriptionClassName,
  imageClassName,
  iconClassName,
  iconContainerClassName,
}) => {
  {
    /*
    hover animation:
      card image shrinks down (container is the same size)
      icon slides to the right
      hover colour (blue) slides diagonally from the top left corner
    blame Paul
  */
  }
  const imageRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState(0);

  const handleResize = useCallback(() => {
    // we want to set the height of the image container to 16:9 ratio of the width
    const width = imageRef.current?.clientWidth;
    setImageHeight(width ? (width * 9) / 16 : 0);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <Link
      to={`/library/${convertToKebabCase(category.name)}`}
      className={`${style.categoryCard} ${className ?? ''}`}
    >
      <div className={`${style.categoryTitle} ${titleClassName ?? ''}`}>
        {category.name}
      </div>
      <div className={style.contentContainer}>
        <div
          className={`${style.categoryImageContainer} ${imageClassName ?? ''}`}
          ref={imageRef}
          style={{ height: imageHeight }}
        >
          <img src={category.previewImage} className={style.categoryImage} />
          <div
            className={`${style.categoryIconContainer} ${
              iconContainerClassName ?? ''
            }`}
          >
            <div className={style.categoryIconHover}>
              <category.Icon
                className={`${style.icon} ${iconClassName ?? ''}`}
              />
            </div>
            <div className={style.categoryIcon}>
              <category.Icon
                className={`${style.icon} ${iconClassName ?? ''}`}
              />
            </div>
          </div>
        </div>
        <div
          className={`${style.categoryDescription} ${
            descriptionClassName ?? ''
          }`}
        >
          {category.shortDescription}
        </div>
      </div>
    </Link>
  );
};
