import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';

export const NeedHelpCard: FC = () => {
  return (
    <div className={style.container}>
      <div className={style.card}>
        <div className={style.textWrapper}>
          <div className={style.title}>
            Do you need help with an issue like this?
          </div>
          <div className={style.description}>
            Ask LawConnect your legal questions for quick, free answers!
          </div>
        </div>
        <div className={style.imageWrapper}>
          <Link
            to={'/chat'}
            state={{ newCase: true, message: undefined }}
            className={style.buttonWrapper}
          >
            <div className={style.buttonText}>Start a case</div>
            <div className={style.buttonArrow}>
              {/* the duplicate is used for hover animation */}
              <div className={style.arrowIconWrapperHover}>
                <ArrowRightIcon className={style.arrowIcon} />
              </div>
              <div className={style.arrowIconWrapper}>
                <ArrowRightIcon className={style.arrowIcon} />
              </div>
            </div>
          </Link>
          <div className={style.backgroundSquare} />
        </div>
      </div>
    </div>
  );
};
