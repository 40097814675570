import React, { FC } from 'react';
import Markdown from 'react-markdown';
import { LibraryCase } from '../../../types/library';
import style from './style.module.less';
import ShieldPlusIcon from '../../../assets/icons/shield-plus.svg?react';

interface Props {
  case: LibraryCase;
}

export const LibraryTextWrapper: FC<Props> = ({ case: libraryCase }) => {
  return (
    <div className={style.container}>
      <div className={style.textWrapper}>
        {/* <div
          className={style.description}
          dangerouslySetInnerHTML={{ __html: libraryCase.description }}
        /> */}
        <Markdown className={style.description}>
          {libraryCase.description}
        </Markdown>
        <div className={style.questionsContainer}>
          <div className={style.questionsTitle}>Questions about this case</div>
          <div className={style.verifiedBanner}>
            <ShieldPlusIcon className={style.verifiedIcon} />
            <span>
              These answers have been verified by a lawyer in the LEAP network
            </span>
          </div>
          <div className={style.questions}>
            {libraryCase.questions.map((q, i) => (
              <div key={i} className={style.questionContainer}>
                <div className={style.questionTitle}>{q.question}</div>
                <div className={style.answer}
                  dangerouslySetInnerHTML={{ __html: q.verifiedAnswer }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
