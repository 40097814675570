/* eslint-disable @stylistic/js/max-len */
import { LibraryCategory } from '../../../../types/library';
import cases from './cases.json';
import criminalImage from '../images/criminal.png';
import ShieldPlusIcon from '../../../../assets/icons/shield-plus.svg?react';
import bubbleImage from '../images/category-icon-image.png';

const TITLE = 'Estates & probates';

const estatesAndProbates: LibraryCategory = {
  name: TITLE,
  title: 'Introduction to Estates and probates in Australia',
  image: criminalImage,
  previewImage: criminalImage,
  shortDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunc',
  cases: cases.filter((c) => c.category === TITLE),
  Icon: ShieldPlusIcon,
  bubbleImage,
  bodyText: [],
};

export default estatesAndProbates;
